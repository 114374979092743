module directives {
    export module master {
        interface IStatusControlScope extends ng.IScope {
            ngDisabled: boolean;
        }

        export class statusDirective implements ng.IDirective {
            restrict = 'E';
            controller = 'statusControlCtrl';
            controllerAs = 'statusControlCtrl';
            templateUrl = 'templates/modules/applicationmain/master/statusControlView.html';
            scope = {
                statusData: '=',
                statusUpdate: '&',
                form: "=",
                ngDisabled: "="
            }

            constructor(private statusService: interfaces.master.IStatusService) {
            }

            link = ($scope: IStatusControlScope, $element: ng.IAugmentedJQuery) => {
                

            }

            static factory(): ng.IDirectiveFactory {
                const directive = (statusService) => new statusDirective(statusService);
                directive.$inject = ['statusService'];

                return directive;
            }
        }
        angular.module("app").directive("gtsStatusControl", statusDirective.factory());
    }
}